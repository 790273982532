import React from "react";

import "../../../styles/payment/creditCard/nameInfo.css";

const NameInfo = () => {
  return (
    <div className="row name-info">
      <p>CARD HOLDER</p>
      <p>VALID TILL</p>
    </div>
  );
};

export default NameInfo;
