import React from "react";

import "../../../styles/payment/creditCard/monoInfo.css";

const MonoInfo = () => {
  return (
    <div className="row mono-info">
      <p>Daniil Kalinevych</p>
      <p>dd / yy</p>
    </div>
  );
};

export default MonoInfo;
